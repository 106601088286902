import React, { useContext, useEffect, useState } from 'react';

import { DeviceType } from '@amzn/monera-app-core';
import { Alert, PageContext, ProductTwisterPlus, SubsBuyBox } from '@amzn/monera-app-core-components';
import { AlertProps } from '@amzn/monera-app-core-components/lib/components/alert/types';
import { isBrowser } from '@amzn/monera-app-core/lib/utils/env';

import { SubscriberAlert } from '../subscriber-alert';
import { SubscriberPlan } from '../subscriber-plan';
import { compareClickHandler, triggerPageUpdate } from './clientSideUtils';
import {
  BuyBoxContainer,
  DeviceSubsDesktopAlertWrapper,
  DeviceSubsMobileAlertWrapper,
  LeftDummyContainer,
  MobileBuyBoxContainer,
  ParentWrapperContainer
} from './styles';
import { TwisterBuyBoxProps } from './types';

const DEFAULT_MOBILE_ALERT_VALUES: Partial<AlertProps> = {
  maxWidth: '412px',
  alertType: 'information'
};

const DEFAULT_DESKTOP_ALERT_VALUES: Partial<AlertProps> = {
  maxWidth: '700px',
  alertType: 'information'
};

const MobileBuyBox = (props: TwisterBuyBoxProps) => {
  if (props.buyBox.alert) {
    return (
      <DeviceSubsMobileAlertWrapper data-cy={'subs-mobile-buy-box-alert'}>
        <Alert {...DEFAULT_MOBILE_ALERT_VALUES} {...props.buyBox.alert} />
      </DeviceSubsMobileAlertWrapper>
    );
  }

  return (
    <MobileBuyBoxContainer id={props.id}>
      <SubscriberAlert {...props.subsAlert} />
      <SubscriberPlan {...props.subsPlan} />
      <ProductTwisterPlus
        {...props.twister}
        isLoading={props.isLoading}
        compareClickHandler={compareClickHandler}
        showMobileTopDivider={false}
      />
      <SubsBuyBox {...props.buyBox} showMobileTopDivider={false} showMobileBottomDivider={false} />
    </MobileBuyBoxContainer>
  );
};

/**
 * Buy box with twister that hides when buy box props are empty.
 * @param props
 * @constructor
 */
export const TwisterBuyBox = (props: TwisterBuyBoxProps) => {
  const triggerPageUpdateHookDeps = [props.buyBox && props.buyBox.callToAction && props.buyBox.callToAction.url];
  // Triggering page update to refresh the persistent navigation widget when ASIN or the checkout url changes.
  useEffect(() => {
    triggerPageUpdate();
  }, triggerPageUpdateHookDeps);

  const deviceType = useContext(PageContext).deviceType;
  if (
    (!props.buyBox || !Object.keys(props.buyBox).length) &&
    (!props.twister || !Object.keys(props.twister).length) &&
    (!props.subsAlert || !Object.keys(props.subsAlert).length) &&
    (!props.subsPlan || !Object.keys(props.subsPlan).length)
  ) {
    return null;
  }

  return deviceType === DeviceType.Mobile || deviceType === DeviceType.MShop ? (
    <MobileBuyBox {...props} />
  ) : (
    <DesktopBuyBox {...props} />
  );
};

const DesktopBuyBox = (props: TwisterBuyBoxProps) => {
  const deviceType = useContext(PageContext).deviceType;
  const getSize = () => ({
    height: isBrowser() ? window.innerHeight : undefined,
    width: isBrowser() ? window.innerWidth : undefined
  });
  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    const handleResize = () => {
      setWindowSize(getSize());
    };
    setWindowSize(getSize());
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (props.buyBox.alert) {
    return (
      <DeviceSubsDesktopAlertWrapper data-cy={'subs-desktop-buy-box-alert'}>
        <Alert {...DEFAULT_DESKTOP_ALERT_VALUES} {...props.buyBox.alert} />
      </DeviceSubsDesktopAlertWrapper>
    );
  }

  const { showPaginationButtons, ...twisterProps } = props.twister;
  let showArrows = showPaginationButtons;
  // Temporary fix to not show the arrows in carousel when screenwidth is >= 1532 and there are 3 items in carousel.
  // Will remove this logic when the carousel issue is fixed.
  if (deviceType === DeviceType.Desktop) {
    if (
      windowSize.width &&
      windowSize.width >= 1532 &&
      props.twister.dimensionValues &&
      props.twister.dimensionValues[0].length === 3
    ) {
      showArrows = false;
    } else {
      showArrows = true;
    }
  }
  return (
    <ParentWrapperContainer>
      <LeftDummyContainer />
      <BuyBoxContainer id={props.id}>
        <SubscriberAlert {...props.subsAlert} />
        <SubscriberPlan {...props.subsPlan} />
        {Object.keys(props.twister).length ? (
          <>
            <ProductTwisterPlus
              {...twisterProps}
              isLoading={props.isLoading}
              showPaginationButtons={showArrows}
              compareClickHandler={compareClickHandler}
            />
          </>
        ) : null}
        <SubsBuyBox {...props.buyBox} />
      </BuyBoxContainer>
    </ParentWrapperContainer>
  );
};
