import React, { useContext, useEffect, useState } from 'react';

import { DeviceType } from '@amzn/monera-app-core';
import { PageContext, RioAlertIcon, RioAlertType, ThemedParagraph } from '@amzn/monera-app-core-components';

import { getLocalizedDate } from './dateTimeUtils';
import {
  StatusAlertHeadingContainer,
  StatusAlertIconContainer,
  StatusAlertWrapper,
  TwoColumnAlertMessageContainer
} from './styles';
import { SubscriberAlertProps } from './types';

/**
 * SubscriberAlert that changes background color based on subscriberAlertType
 * @param props
 * @constructor
 */
export const SubscriberAlert = (props: SubscriberAlertProps) => {
  const deviceType = useContext(PageContext).deviceType;
  const isMobile = deviceType === DeviceType.Mobile || deviceType === DeviceType.MShop;
  const MONTH_DAY_FORMAT = 'MM/DD';
  const [localizedDate, setLocalizedDate] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).P.when('A').execute(() => {
        const utcDateString = props.subscriptionEndDate;
        const localizedDateValue = getLocalizedDate(utcDateString, MONTH_DAY_FORMAT);
        setLocalizedDate(localizedDateValue);
      });
    }
  }, [props.subscriptionEndDate]);

  if (!props || !Object.keys(props).length) {
    return <></>;
  }
  const formattedLocalizedDate = localizedDate.trim() ? `${localizedDate.trim()}.` : '';
  const alertDataCyPrefix =
    'subscriber-alert-' + (props.subscriberAlertType ? props.subscriberAlertType : 'none') + '-';

  return (
    <>
      <StatusAlertWrapper isMobile={isMobile} alertType={props.subscriberAlertType}>
        {props.subscriberAlertHeading && (
          <StatusAlertHeadingContainer
            bold
            headingLevel={4}
            font={isMobile ? 'extraLarge' : 'large'}
            data-cy={alertDataCyPrefix + 'heading'}
          >
            {props.subscriberAlertHeading}
          </StatusAlertHeadingContainer>
        )}
        {props.subscriberAlertMessage && (
          <TwoColumnAlertMessageContainer data-cy={alertDataCyPrefix + 'message'}>
            {props.subscriberAlertType && (
              <StatusAlertIconContainer>
                <RioAlertIcon alertType={props.subscriberAlertType as RioAlertType} size={20} />
              </StatusAlertIconContainer>
            )}
            <ThemedParagraph font={'base'}>{props.subscriberAlertMessage + formattedLocalizedDate}</ThemedParagraph>
          </TwoColumnAlertMessageContainer>
        )}
      </StatusAlertWrapper>
    </>
  );
};
