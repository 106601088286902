import styled from 'styled-components';

export const StyledAltBuyboxContainer = styled.div`
  z-index: 2;
  background-color: #ffffff;
  border: 1px solid #d5d9d9;
  border-radius: 18px;
  flex-direction: column;
  width: calc(40%);
  max-width: 600px;
  min-width: 400px;
  margin-top: 40px;
  padding: 4px 24px 0px 24px;
  #invite-platform-trigger {
    width: 100%;
  }
`;

export const StyledMobileAltBuyboxContainer = styled.div`
  #invite-platform-trigger {
    max-width: 550px;
  }
`;
