import styled from 'styled-components';

import {
  SubsTwisterBuyBoxAlertDesktopWrapper,
  SubsTwisterBuyBoxAlertMobileWrapper
} from '@amzn/monera-app-core-components/lib/components/subs-twister-buy-box/styles';

export const ParentWrapperContainer = styled.div`
  display: flex;
  flex: 1;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 1500px;
  min-width: 1000px;
  padding-right: 60px;
`;

export const LeftDummyContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const BuyBoxContainer = styled.div`
  z-index: 2;
  background-color: #ffffff;
  border: 1px solid #d5d9d9;
  border-radius: 18px;
  flex-direction: column;
  width: calc(40%);
  max-width: 600px;
  min-width: 400px;
  margin-top: 40px;
  padding-top: 4px;
  padding-bottom: ${(props) => props.theme.spacings.mediumPlus};
  padding-left: ${(props) => props.theme.spacings.large};
  padding-right: ${(props) => props.theme.spacings.large};
`;

export const MobileBuyBoxContainer = styled.div``;

/**
 * Desktop wrapper to contain the Subscription purchase information alert.
 */
export const DeviceSubsDesktopAlertWrapper = styled(SubsTwisterBuyBoxAlertDesktopWrapper)`
  align-items: center;
`;

/**
 * Mobile wrapper to contain the Subscription purchase information alert.
 */
export const DeviceSubsMobileAlertWrapper = styled(SubsTwisterBuyBoxAlertMobileWrapper)`
  align-items: center;
`;
