import React from 'react';

import { AuiContainerProps } from './types';

/**
 * The component renders an AUI container element.
 * The content inside the AuiContainer component must be passed through the children props.
 */
export const AuiContainer = (props: AuiContainerProps) => {
  const { children } = props;

  return (
    <div className="a-container">
      <div className="a-row">
        <div className="a-column a-span12">{children}</div>
      </div>
    </div>
  );
};
