import React, { useContext, useEffect, useState } from 'react';

import { DeviceType } from '@amzn/monera-app-core';
import { PageContext, ThemedHeading, ThemedParagraph, ThemedSpan } from '@amzn/monera-app-core-components';
import { SafeHtmlParse } from '@amzn/monera-app-core-components/lib/components/html-widget/view';

import { getLocalizedDate } from '../subscriber-alert/dateTimeUtils';
import {
  ManageSubsButton,
  PlanFooterContainer,
  PlanHeadingContainer,
  PlanMessageContainer,
  SubscribedPlanContainer,
  SubscriberPlanDetailsContainer
} from './styles';
import { SubscriberPlanProps } from './types';

export const SubscriberPlan = (props: SubscriberPlanProps) => {
  const deviceType = useContext(PageContext).deviceType;
  const isMobile = deviceType === DeviceType.Mobile || deviceType === DeviceType.MShop;

  const [isOpen, setIsOpen] = useState(false);

  const [localizedPlanEndDate, setLocalizedDate] = useState('');
  const MONTH_DAY_YEAR_FORMAT = 'MMMM D, YYYY';
  useEffect(() => {
    if (typeof window !== 'undefined') {
      (window as any).P.when('A').execute(() => {
        const utcDateString = props.subscriberPlanEndDate;
        const localizedDateValue = getLocalizedDate(utcDateString, MONTH_DAY_YEAR_FORMAT);
        setLocalizedDate(localizedDateValue);
      });
    }
  }, [props.subscriberPlanEndDate]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  if (!props || Object.keys(props).length == 0) {
    return <></>;
  }
  const planDataCyPrefix = 'subscriber-plan-';

  return (
    <>
      {props.planHeading && (
        <PlanHeadingContainer
          bold
          font={'extraLarge'}
          headingLevel={3}
          isMobile={isMobile}
          data-cy={planDataCyPrefix + 'heading'}
        >
          {props.planHeading}
        </PlanHeadingContainer>
      )}
      {props.planMessage && (
        <PlanMessageContainer font={'basePlus'} isMobile={isMobile} data-cy={planDataCyPrefix + 'message'}>
          {SafeHtmlParse(props.planMessage)}
        </PlanMessageContainer>
      )}
      {props.planDetailsHeading && (
        <SubscriberPlanDetailsContainer isOpen={isOpen} data-cy={planDataCyPrefix + 'details'} onClick={handleClick}>
          <ThemedHeading bold font={isMobile ? 'medium' : 'mediumPlus'} headingLevel={4}>
            {props.planDetailsHeading}
          </ThemedHeading>
          <i className="a-icon a-icon-dropdown" />
        </SubscriberPlanDetailsContainer>
      )}
      {isOpen && (
        <>
          <SubscribedPlanContainer hasMargin={false} data-cy={planDataCyPrefix + 'name'}>
            <ThemedHeading bold font={'basePlus'} headingLevel={4}>
              {props.planNameHeading}
            </ThemedHeading>
            <ThemedParagraph font={isMobile ? 'base' : 'basePlus'}>{props.subscriberPlanName}</ThemedParagraph>
          </SubscribedPlanContainer>
          <SubscribedPlanContainer hasMargin={true} data-cy={planDataCyPrefix + 'renewal'}>
            <ThemedHeading bold font={isMobile ? 'base' : 'basePlus'} headingLevel={4}>
              {props.renewalDateHeading}
            </ThemedHeading>
            <ThemedParagraph font={isMobile ? 'base' : 'basePlus'}>
              {props.planEndDateText + localizedPlanEndDate}
            </ThemedParagraph>
          </SubscribedPlanContainer>
        </>
      )}
      {props.planButtonCta && (
        <ManageSubsButton
          planButtonType={props.planButtonType}
          data-cy={planDataCyPrefix + 'button'}
          onClick={() => {
            if (props.planButtonUrl) {
              window.location.href = props.planButtonUrl;
            }
          }}
        >
          {props.planButtonCta}
        </ManageSubsButton>
      )}
      {props.planFooterText && (
        <PlanFooterContainer isMobile={isMobile} data-cy={planDataCyPrefix + 'footer'}>
          <ThemedSpan font={isMobile ? 'small' : 'base'}>{SafeHtmlParse(props.planFooterText)}</ThemedSpan>
        </PlanFooterContainer>
      )}
    </>
  );
};
