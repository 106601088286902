import React, { Children, FunctionComponent, isValidElement, PropsWithChildren, ReactNode, useContext } from 'react';

import { DeviceType } from '@amzn/monera-app-core';
import { PageContext } from '@amzn/monera-app-core-components';

import { LeftDummyContainer, ParentWrapperContainer } from '../twister-buy-box/styles';
import { StyledAltBuyboxContainer, StyledMobileAltBuyboxContainer } from './styles';
import { AltBuyboxContainerProps } from './types';

/**
 * Email Me When Ready container
 * @param props
 * @constructor
 */
export const AltBuyboxContainer: FunctionComponent<PropsWithChildren<AltBuyboxContainerProps>> = (props) => {
  const deviceType = useContext(PageContext).deviceType;
  const { children, useOnlyRestrictedMshopSlot = false, ...rest } = props;

  // mutually exclusive restricted mshop slots and all other slots
  // chose to show one or the other based on flag
  const renderChildren = Children.toArray(children).filter(
    (child) => isValidElement(child) && (child.props?.state?.restrictedMshop ?? false) === useOnlyRestrictedMshopSlot
  );

  if (!hasValidChildElement(renderChildren)) {
    return null;
  }

  return deviceType === DeviceType.Mobile || deviceType === DeviceType.MShop ? (
    <>
      <StyledMobileAltBuyboxContainer data-cy={'alt-buybox-container'} {...rest}>
        {renderChildren}
      </StyledMobileAltBuyboxContainer>
    </>
  ) : (
    <ParentWrapperContainer>
      <LeftDummyContainer />
      <StyledAltBuyboxContainer data-cy={'alt-buybox-container'} {...rest}>
        {renderChildren}
      </StyledAltBuyboxContainer>
    </ParentWrapperContainer>
  );
};

/**
 * Short solution to verify if at least one non-empty child component.
 * The long term solution should check the AltBuybox slot contents in MoneraService,
 * and pass a props to AltBuyboxContainer to indicate if this needs to render or not.
 * TODO: https://sim.amazon.com/issues/P98323306
 */
const hasValidChildElement = (children: ReactNode) => {
  let hasElement = false;
  Children.forEach(children, (child) => {
    if (!isValidElement(child) || hasElement) {
      return;
    }
    if (child?.props?.state?.html && child.props.state.html !== '') {
      hasElement = true;
    }
  });
  return hasElement;
};
