import styled, { css } from 'styled-components';

import { Gray_1600, Green_1400, Yellow_1500 } from '@amzn/monera-app-core';
import { ThemedHeading, ThemedHeadingProps } from '@amzn/monera-app-core-components';

/**
 * Defined object that maps alert types to their respective background colors
 */
const STATUS_ALERT_BACKGROUND_COLOR = {
  information: '#EDFDFF',
  success: Green_1400,
  warning: Yellow_1500
} as const;

/**
 * Helper function to retrieve the background color based on the alert type
 */
const getAlertBackground = (alertType: string) => {
  const alertTypeKey = alertType as keyof typeof STATUS_ALERT_BACKGROUND_COLOR;
  return STATUS_ALERT_BACKGROUND_COLOR[alertTypeKey];
};

/**
 * Helper function to retrieve the bottom padding of the alert based on device type
 */
const getAlertPaddingBottom = (isMobile: boolean) => {
  return isMobile ? '16px' : '20px';
};

/**
 * Common styles for deviceTypes: Mobile, MShop, and Desktop
 */
const sharedStyles = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: auto;
`;

/**
 * StatusAlertWrapper margin when deviceType is Mobile or MShop
 */
const mobileStyles = css`
  margin: 0 -14px;
  padding: 16px 14px 0 14px;
  row-gap: 10px;
`;

/**
 * StatusAlertWrapper styles when deviceType is Desktop
 */
const desktopStyles = css`
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  margin: -4px -24px 0;
  padding: 20px 24px 0 24px;
  row-gap: 7px;
`;

/**
 * StatusAlertWrapper is a styled component that extends the background color beyond the child container's padding
 * @param props.isMobile {boolean} - Determines whether the component is rendered on a mobile device or not
 * @param props.alertType {string} - The type of alert (information, success, warning) to set the background color
 */
export const StatusAlertWrapper = styled.div<{
  isMobile: boolean;
  alertType?: string;
}>`
  ${sharedStyles}
  ${(props) => (props.isMobile ? mobileStyles : desktopStyles)}
  z-index: 2;
  background-color: ${(props) => (props.alertType ? getAlertBackground(props.alertType) : Gray_1600)};
  padding-bottom: ${(props) => (props.alertType ? getAlertPaddingBottom(props.isMobile) : '0')};
`;

/**
 * StatusAlertHeadingContainer is a styled component that holds the content of the alert heading
 */
export const StatusAlertHeadingContainer = styled(ThemedHeading)<ThemedHeadingProps>`
  && {
    padding-bottom: 0;
  }
`;

/**
 * StatusAlertContainer is a styled component that holds the content of the alert icon
 */
export const StatusAlertIconContainer = styled.div`
  && {
    height: 18px;
    width: 20px;
  }
`;

/**
 * TwoColumnAlertMessageContainer is a styled component that holds the alert icon and the alert message in a two-column layout
 */
export const TwoColumnAlertMessageContainer = styled.div`
  display: flex;
  z-index: 2;
  flex: 1;
  gap: 8px;
  flex-direction: row;
  justify-content: start;
  width: auto;
`;
