import React from 'react';

import * as CoreComponents from '@amzn/monera-app-core-components/lib/components';

import * as DeviceSubsComponents from '../components';

export const componentsMap: Record<string, React.ComponentType<any>> = {
  //////////////////////////////////////
  // @amzn/monera-app-core-components //
  //////////////////////////////////////
  breadcrumbs: CoreComponents.Breadcrumbs,
  hero: CoreComponents.Hero,
  head: CoreComponents.CanvasContainer,
  redirector: DeviceSubsComponents.Redirector,
  'buy-now-buy-box': CoreComponents.BuyBox,
  'average-customer-reviews': CoreComponents.CustomerReviews,
  'average-customer-reviews-widget': CoreComponents.HtmlWidget,
  'html-widget': CoreComponents.HtmlWidget,
  'order-details': CoreComponents.OrderDetails,
  'product-description': CoreComponents.ProductDescription,
  'canvas-container': CoreComponents.CanvasContainer,
  'canvas-item': CoreComponents.CanvasItem,
  'customer-reviews-medley': CoreComponents.HtmlWidget,
  'product-price': CoreComponents.ProductPrice,
  'product-image': CoreComponents.ProductImage,
  'product-contributors': CoreComponents.ProductContributors,
  'product-title': CoreComponents.ProductTitle,
  'product-information': CoreComponents.ProductInformation,
  'product-feature-bullets': CoreComponents.ProductFeatureBullets,
  'meta-tag': CoreComponents.MetaTag,
  'order-confirmation': CoreComponents.OrderConfirmation,
  divider: CoreComponents.Divider,
  'order-confirmation-details': CoreComponents.OrderConfirmationDetails,
  'order-fulfillment': CoreComponents.OrderFulfillment,
  'hero-banner': CoreComponents.Media,
  'media-carousel': CoreComponents.MediaCarousel,
  'product-twister-desktop': CoreComponents.DesktopProductTwister,
  'device-subs-twister-plus': CoreComponents.ProductTwisterPlus,
  unavailability: CoreComponents.Alert,
  'device-subs-buy-box': CoreComponents.SubsBuyBox,
  'atf-marker': CoreComponents.AtfMarker,
  'btf-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'atf-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'atf-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'atf-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-4-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-5-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-6-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-7-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-8-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-9-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-10-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-4-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-5-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-6-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-7-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-8-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-9-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-10-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  loading: CoreComponents.Loading,
  'variation-context-container': CoreComponents.VariationContextContainer,
  //////////////////////////////////////
  // device subscription components //
  //////////////////////////////////////
  'aui-container': DeviceSubsComponents.AuiContainer,
  'aui-sub-header': DeviceSubsComponents.AuiSubHeader,
  'buybox-data-CS-slot': DeviceSubsComponents.EmptyComponent,
  'blockedbuybox-data-CS-slot': DeviceSubsComponents.EmptyComponent,
  data: DeviceSubsComponents.EmptyComponent,
  'twister-buybox': DeviceSubsComponents.TwisterBuyBox,
  'twister-data-CS-slot': DeviceSubsComponents.EmptyComponent,
  'custom-script': DeviceSubsComponents.CustomScriptComponent,
  'custom-script-CS-slot': DeviceSubsComponents.EmptyComponent,
  'blocked-buybox-container': DeviceSubsComponents.BlockedBuyboxContainer,
  'device-subs-meta-tag': CoreComponents.MetaTag,
  'alt-buybox-container': DeviceSubsComponents.AltBuyboxContainer,
  'atf-top-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'atf-top-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'atf-top-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-11-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-12-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-13-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-14-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-15-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-16-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-17-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-18-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-19-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-20-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-21-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'btf-22-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'alt-buybox-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'alt-buybox-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'alt-buybox-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'alt-buybox-4-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-top-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-top-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-atf-top-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-11-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-12-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-13-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-14-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-15-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-16-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-17-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-18-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-19-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-20-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-21-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-btf-22-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-alt-buybox-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-alt-buybox-2-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-alt-buybox-3-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-alt-buybox-4-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget,
  'mobile-alt-buybox-restricted-mshop-1-CS-slot': CoreComponents.ServerRenderedHtmlParserWidget
};
