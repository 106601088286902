import { useEffect } from 'react';

import { RedirectorProps } from './types';

/**
 * Redirector that redirects to provided uri if it is provided
 * @param props
 * @returns
 */
export const Redirector = (props: RedirectorProps) => {
  useEffect(() => {
    if (props.redirectUri) {
      window.location.href = props.redirectUri;
    }
  }, [props.redirectUri]);
  return null;
};
