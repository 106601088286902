import React from 'react';

import { ServerRenderedHtmlParserWidget } from '@amzn/monera-app-core-components';

import { CustomScriptProps } from './types';

export const CustomScriptComponent = (props: CustomScriptProps) => {
  const { script } = props;

  if (!script) {
    return null;
  }

  return <ServerRenderedHtmlParserWidget html={`<script>${script}</script>`} isDangerousHtml={true} />;
};
