import React from 'react';

import { AuiSubHeaderProps } from './types';

/**
 * The mobile component renders an AUI sub header element.
 * This acts like a sub navigation, which brings customers
 * to Amazon Devices home page.
 */
export const AuiSubHeader = (props: AuiSubHeaderProps) => {
  const { title, url } = props;

  return (
    <div className="a-subheader">
      <a href={url}>
        <i className="a-icon a-icon-page-back a-subheader-back"></i>
        <h4>{title}</h4>
      </a>
    </div>
  );
};
