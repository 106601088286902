import styled from 'styled-components';

import { rioAlertColors } from '@amzn/monera-app-core';
import { ThemedSpan } from '@amzn/monera-app-core-components';
import {
  SubsTwisterBuyBoxAlertDesktopWrapper,
  SubsTwisterBuyBoxAlertMobileWrapper
} from '@amzn/monera-app-core-components/lib/components/subs-twister-buy-box/styles';

export const DesktopBlockedBuyboxMessageWrapper = styled(SubsTwisterBuyBoxAlertDesktopWrapper)`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileBlockedBuyboxMessageWrapper = styled(SubsTwisterBuyBoxAlertMobileWrapper)`
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  margin: 0 !important;
`;

export const BlockedBuyboxMessage = styled(ThemedSpan)`
  color: ${rioAlertColors.error};
  text-align: center;
`;
