import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';

import { DeviceType } from '@amzn/monera-app-core';
import { PageContext } from '@amzn/monera-app-core-components';
import { SafeHtmlParse } from '@amzn/monera-app-core-components/lib/components/html-widget/view';

import { EmptyComponent } from '../empty-component';
import {
  BuyBoxContainer,
  LeftDummyContainer,
  MobileBuyBoxContainer,
  ParentWrapperContainer
} from '../twister-buy-box/styles';
import { BlockedBuyboxMessage, DesktopBlockedBuyboxMessageWrapper, MobileBlockedBuyboxMessageWrapper } from './styles';
import { BlockedBuyboxContainerProps } from './types';

/**
 * Blocked Buybox Container
 * @param props
 * @constructor
 */
export const BlockedBuyboxContainer: FunctionComponent<PropsWithChildren<BlockedBuyboxContainerProps>> = (props) => {
  const deviceType = useContext(PageContext).deviceType;
  const { children, ...rest } = props;
  if (!rest.blockedBuyboxMessage) {
    return <EmptyComponent />;
  }
  return deviceType === DeviceType.Mobile || deviceType === DeviceType.MShop ? (
    <MobileBlockedBuybox {...rest} />
  ) : (
    <DesktopBlockedBuybox {...rest} />
  );
};

const DesktopBlockedBuybox = (props: BlockedBuyboxContainerProps) => {
  return (
    <ParentWrapperContainer>
      <LeftDummyContainer />
      <BuyBoxContainer>
        <DesktopBlockedBuyboxMessageWrapper>
          <BlockedBuyboxMessage font={'base'}>{SafeHtmlParse(props.blockedBuyboxMessage)}</BlockedBuyboxMessage>
        </DesktopBlockedBuyboxMessageWrapper>
      </BuyBoxContainer>
    </ParentWrapperContainer>
  );
};

const MobileBlockedBuybox = (props: BlockedBuyboxContainerProps) => {
  return (
    <MobileBuyBoxContainer>
      <MobileBlockedBuyboxMessageWrapper>
        <BlockedBuyboxMessage font={'base'}>{SafeHtmlParse(props.blockedBuyboxMessage)}</BlockedBuyboxMessage>
      </MobileBlockedBuyboxMessageWrapper>
    </MobileBuyBoxContainer>
  );
};
