const COMPARE_SECTION_ID = 'compare';
const PWN_COMPARE_TAB_ID = 'jumpLinksSubNavCompareTab';

/**
 * Checking if Persistent navigation widget click function exists on the page.
 * If yes, will use the jump function from the widget,
 * otherwise, use the normal function.
 */
export const compareClickHandler = (event: any) => {
  event.preventDefault();
  (window as any).P.when('A').execute((A: any) => {
    const pnwCompareTab = document.getElementById(PWN_COMPARE_TAB_ID);
    if (pnwCompareTab) {
      // eslint-disable-next-line no-underscore-dangle
      if (A.$._data(pnwCompareTab, 'events')) {
        document.getElementById(PWN_COMPARE_TAB_ID)?.click();
        return;
      }
    }
    document.getElementById(COMPARE_SECTION_ID)?.scrollIntoView();
  });
};

/**
 * Triggers the page update to refreshes components
 * like the Persistent navigation widget.
 */
export const triggerPageUpdate = () => {
  (window as any).P.when('A').execute((A: any) => {
    A.trigger('a:pageUpdate');
  });
};
