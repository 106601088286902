import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Converts given UTC date ['Sun Aug 11 05:35:02 UTC 2024'] to browser time zone.
 * Returns localized date in given format 'MM/DD': '08/11' or 'MMMM D, YYYY': 'August 8, 2024'.
 * @param dateString
 * @param format
 */
export const getLocalizedDate = (dateString?: string, format?: string) => {
  if (!dateString || !format) {
    return '';
  }
  try {
    const utcDate = dayjs.utc(dateString);
    if (!utcDate.isValid()) {
      return '';
    }
    const localDateObj = utcDate.tz(dayjs.tz.guess());
    const localDate = dayjs(localDateObj);
    return localDate.isValid() ? ` ${localDate.format(format)}` : '';
  } catch (error) {
    return '';
  }
};
