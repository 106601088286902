import styled, { css } from 'styled-components';

import { Gray_100, Gray_1200, Gray_1600, ThemedProps, Yellow_900 } from '@amzn/monera-app-core';
import { ThemedHeading, ThemedHeadingProps, ThemedParagraph, ThemedTextProps } from '@amzn/monera-app-core-components';

const PLAN_BUTTON_COLOR = {
  PRIMARY: Yellow_900
} as const;

/**
 * Helper function to retrieve the button color based on type
 */
const getButtonColor = (buttonType: string) => {
  const buttonTypeKey = buttonType as keyof typeof PLAN_BUTTON_COLOR;
  return PLAN_BUTTON_COLOR[buttonTypeKey];
};

/**
 * Common styles for header and message containers
 */
const sharedContainerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: auto;
`;

export const SubscriberPlanDetailsContainer = styled.div.attrs<{ isOpen: boolean }>((props) => ({
  'aria-expanded': props.isOpen
}))<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
  i {
    transition: transform 0.5s;
    margin-right: 10px;
    transform: ${(props) => props.isOpen && 'rotate(0.5turn)'};
  }
  ${(props) =>
    props.isOpen
      ? `
      padding-bottom: 0;
  `
      : `
      padding-bottom: 12px;
  `}
`;

export const SubscribedPlanContainer = styled.div<{ hasMargin: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  ${(props) => (props.hasMargin ? `padding: 12px 0 12px 0;` : `padding: 12px 0 4px 0;`)}
`;

export const PlanHeadingContainer = styled(ThemedHeading)<ThemedHeadingProps & { isMobile: boolean }>`
  ${sharedContainerStyles}
  ${(props) => (props.isMobile ? `padding: 16px 0 0 0;` : `padding: 20px 0 0 0;`)}
`;

export const PlanMessageContainer = styled(ThemedParagraph)<ThemedProps<ThemedTextProps> & { isMobile: boolean }>`
  ${sharedContainerStyles}
  ${(props) => (props.isMobile ? `padding: 16px 0 0 0;` : `padding: 20px 0 0 0;`)}
  margin: 0;
`;

export const ManageSubsButton = styled.button<{
  planButtonType?: string;
}>`
  width: 100%;
  border-radius: 24px;
  background-color: ${(props) => (props.planButtonType ? getButtonColor(props.planButtonType) : Gray_1600)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  height: auto;
  min-height: 45px;
  font-family: 'Amazon Ember', Arial, sans-serif !important;
  font-weight: 400 !important;
  color: ${Gray_100} !important;
  font-size: 16px;
  line-height: 21px;
  ${(props) =>
    props.planButtonType !== 'PRIMARY'
      ? `
        border: 1px solid ${Gray_1200};
      `
      : `
        border: 0px;
      `}
`;

export const PlanFooterContainer = styled.div<{ isMobile: boolean }>`
  ${sharedContainerStyles}
  && {
    margin-top: ${(props) => props.theme.spacings.medium};
    margin-bottom: 0;
  }
`;
